import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PMSDailyBriefProvider extends HttpRequest {
  getAllDailyBrief (query) {
    this.setHeader(getAuthToken())
    return this.get('/daily-brief', query)
  }

  getAllDailyBriefAcceptById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/daily-brief/${id}/accept`)
  }

  getAllDailyBriefAcceptPendingById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/daily-brief/${id}/pending`)
  }
}

export default PMSDailyBriefProvider
