<template>
  <v-dialog
    v-model="dialog.active"
    max-width="80%">
    <v-card>
      <v-card-title>
        Accepted Logs
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="acceptDetail"
          :items-per-page="-1"
          :loading="loading"
          hide-default-footer
          :search="search"
          class="elevation-3">
          <template #top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template #[`item.createdAt`]="{ item }">
            {{ getDateFormat(item.createdAt) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import DailyBriefsProvider from '@/resources/PMSDailyBrief.provider'

const DailyBriefsService = new DailyBriefsProvider()

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          align: 'center'
        },
        {
          text: 'Email',
          value: 'email',
          align: 'center'
        }
      ],
      acceptDetail: [],
      loading: false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'dialog.active': {
      handler () {
        if (this.dialog.active) {
          this.getDailyBriefsAccept()
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    getDateFormat (date) {
      if (!date) {
        return '-'
      }

      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    async getDailyBriefsAccept () {
      try {
        this.loading = true

        const { data } = await DailyBriefsService.getAllDailyBriefAcceptById(this.dialog.id)
        this.acceptDetail = data
      } catch (error) {
        console.error('getDailyBriefsAccept', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error?.message || error}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
