var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded full-container elevation-3",attrs:{"fluid":""}},[_c('image-preview',{model:{value:(_vm.selectedImage),callback:function ($$v) {_vm.selectedImage=$$v},expression:"selectedImage"}}),_c('daily-brief-accept-modal',{model:{value:(_vm.selectedDailyBrief),callback:function ($$v) {_vm.selectedDailyBrief=$$v},expression:"selectedDailyBrief"}}),_c('daily-brief-pending-accept-modal',{model:{value:(_vm.selectedPendingDailyBrief),callback:function ($$v) {_vm.selectedPendingDailyBrief=$$v},expression:"selectedPendingDailyBrief"}}),_c('v-row',{attrs:{"justify-md":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h2',[_vm._v("Daily Briefs Report")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":"","nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('fields.DateRange'),"append-icon":"mdi-calendar","readonly":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.dateSelected),callback:function ($$v) {_vm.dateSelected=$$v},expression:"dateSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerMenu),callback:function ($$v) {_vm.datePickerMenu=$$v},expression:"datePickerMenu"}},[_c('v-date-picker',{staticClass:"elevation-3",attrs:{"color":"secondary"},model:{value:(_vm.dateSelected),callback:function ($$v) {_vm.dateSelected=$$v},expression:"dateSelected"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('search-box',{on:{"on-search":function($event){return _vm.getDailyBriefs(true)}},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dailyBriefs,"items-per-page":_vm.query.limit,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFormat(item.createdAt))+" ")]}},{key:"item.messages",fn:function(ref){
var item = ref.item;
return _vm._l((item.messages),function(message,i){return _c('div',{key:i},[(message.type === 'text')?_c('p',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(message.value)+" ")]):(message.type === 'image')?_c('img',{staticClass:"message-img my-2 rounded elevation-3",attrs:{"src":message.value,"alt":"Daily Brief Image"},on:{"click":function($event){return _vm.previewImg(message.value)}}}):_c('p',{staticClass:"mt-3"},[_vm._v(" - ")])])})}},{key:"item.accepted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"accepted-link",on:{"click":function($event){return _vm.showAcceptDetail(item.id)}}},[_vm._v(" "+_vm._s(item.accepted)+" ")])]}},{key:"item.pending",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"accepted-link",on:{"click":function($event){return _vm.showPendingAcceptDetail(item.id)}}},[_vm._v(" "+_vm._s(item.pending)+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"disabled":_vm.loading,"total-visible":"7","color":"secondary"},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }