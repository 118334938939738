<template>
  <v-container
    fluid
    class="rounded full-container elevation-3">
    <image-preview v-model="selectedImage" />
    <daily-brief-accept-modal v-model="selectedDailyBrief" />
    <daily-brief-pending-accept-modal v-model="selectedPendingDailyBrief" />

    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="6">
        <h2>Daily Briefs Report</h2>
      </v-col>
      <v-col
        cols="12"
        md="3">
        <v-menu
          v-model="datePickerMenu"
          close-on-content-click
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="dateSelected"
              :label="$t('fields.DateRange')"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              hide-details
              dense
              outlined
              v-on="on" />
          </template>
          <v-date-picker
            v-model="dateSelected"
            color="secondary"
            class="elevation-3">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="3">
        <search-box
          v-model="query.search"
          @on-search="getDailyBriefs(true)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="dailyBriefs"
          :items-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template #[`item.createdAt`]="{ item }">
            {{ getDateFormat(item.createdAt) }}
          </template>
          <template #[`item.messages`]="{ item }">
            <div
              v-for="(message, i) in item.messages"
              :key="i">
              <p
                v-if="message.type === 'text'"
                class="mt-3">
                {{ message.value }}
              </p>
              <img
                v-else-if="message.type === 'image'"
                :src="message.value"
                alt="Daily Brief Image"
                class="message-img my-2 rounded elevation-3"
                @click="previewImg(message.value)">
              <p
                v-else
                class="mt-3">
                -
              </p>
            </div>
          </template>
          <template #[`item.accepted`]="{ item }">
            <span
              class="accepted-link"
              @click="showAcceptDetail(item.id)">
              {{ item.accepted }}
            </span>
          </template>
          <template #[`item.pending`]="{ item }">
            <span
              class="accepted-link"
              @click="showPendingAcceptDetail(item.id)">
              {{ item.pending }}
            </span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import DailyBriefsProvider from '@/resources/PMSDailyBrief.provider'
import SearchBox from '@/components/SearchBox.vue'
import ImagePreview from '@/views/management/components/ImagePreview.vue'
import DailyBriefAcceptModal from '../components/DailyBriefAcceptModal.vue'
import DailyBriefPendingAcceptModal from '../components/DailyBriefPendingAcceptModal.vue'

const DailyBriefsService = new DailyBriefsProvider()

export default {
  components: { SearchBox, ImagePreview, DailyBriefAcceptModal, DailyBriefPendingAcceptModal },
  data () {
    return {
      loading: false,
      query: {
        search: '',
        page: 1,
        limit: 10
      },
      pageCount: 0,
      datePickerMenu: false,
      dateSelected: this.$dayjs().format('YYYY-MM-DD'),
      headers: [
        {
          text: 'Date',
          value: 'createdAt',
          align: 'center'
        },
        {
          text: 'Messages',
          value: 'messages',
          align: 'start',
          sortable: false
        },
        {
          text: 'Pending',
          value: 'pending',
          align: 'center',
          sortable: false
        },
        {
          text: 'Accepted',
          value: 'accepted',
          align: 'center',
          sortable: false
        }
      ],
      selectedImage: {
        active: false,
        imgSrc: null
      },
      selectedDailyBrief: {
        active: false,
        id: null
      },
      selectedPendingDailyBrief: {
        active: false,
        id: null
      },
      dailyBriefs: []
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getDailyBriefs()
      },
      deep: true
    }
  },
  mounted () {
    this.getDailyBriefs(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    getDateFormat (date) {
      if (!date) {
        return '-'
      }

      return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    async getDailyBriefs (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.dailyBriefs = []
        }

        const { data } = await DailyBriefsService.getAllDailyBrief({
          ...this.query,
          date: this.dateSelected
        })

        let tmpResults = [...data?.results || []]

        if (tmpResults.length) {
          const promiseResults = await Promise.all(tmpResults.map(async (result) => ({
            id: result.id,
            pending: await DailyBriefsService.getAllDailyBriefAcceptPendingById(result.id)
          })))

          const mappedPending = promiseResults.map((result) => ({ id: result.id, pending: result?.pending?.data || null }))
          tmpResults = tmpResults.map((result) => {
            const foundPending = mappedPending.find((p) => p.id === result.id)

            return {
              ...result,
              pending: foundPending?.pending?.length || 0
            }
          })
        }

        this.dailyBriefs = tmpResults
        this.pageCount = data.pages
      } catch (error) {
        console.error('getDailyBriefs', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error?.message || error}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    previewImg (img) {
      this.selectedImage = {
        active: true,
        imgSrc: img
      }
    },
    showAcceptDetail (id) {
      this.selectedDailyBrief = {
        active: true,
        id
      }
    },
    showPendingAcceptDetail (id) {
      this.selectedPendingDailyBrief = {
        active: true,
        id
      }
    }
  }
}
</script>

<style scoped>
.full-container {
  background-color: white;
  min-height: calc(100vh - 68px);
}
.message-img {
  cursor: pointer;
  width: 150px;
}
.accepted-link {
  color: black;
  text-decoration: underline solid black 2px;
  cursor: pointer;
  font-weight: bold;
}
</style>
